import React from "react"
import { Layout, Works, SEO } from "../components"
import works from "../constants/works"

const WorksPage = () => {
  return (
    <Layout>
      <SEO title="作品" />
      <Works works={works} title="作品" />
    </Layout>
  )
}

export default WorksPage
